import React from 'react'
import { graphql } from 'gatsby'

import { Layout, ProjectSnippet } from '../components'
import Grid, { GridItem } from '../components/grid'

import Seo from "../components/seo/Seo"

/**
 * This Template is used to generate pages for Pagination
 */

const ProjectList = ({ data, pageContext }) => {

  const posts = data.allMdx.edges
  const title = data.site.siteMetadata.title
  const { currentPage, numberOfPages } = pageContext
  const slug = `page/${currentPage}`

  return (
    <Layout>
      <Seo
        title={title}
        description={`${title} ${currentPage} page.`}
        slug={slug} />
      <main className="projects">
        <h1>Portfolio</h1>
        <Grid>
          {posts.map(({ node }) => (
            <GridItem key={node.id} item className="postGridItem" >
              <ProjectSnippet
                key={node.id}
                data={node.frontmatter} />
            </GridItem>
          ))}
        </Grid>
      </main>
    </Layout>
  )
}

export const projectListQuery = graphql`{
  site {
    siteMetadata {
      title
    }
  }
  allMdx(
    sort: {fields: [frontmatter___date], order: DESC}
    filter: {frontmatter: {published: {eq: true}}}
  ) {
    edges {
      node {
        id
        frontmatter {
          title
          description
          projectLink
          githubLink
          date(formatString: "MMMM DD, YYYY")
          image {
            childImageSharp {
              gatsbyImageData(height: 120, layout: FIXED)
            }
          }
        }
      }
    }
  }
}
`

export default ProjectList
